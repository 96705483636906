import React from "react";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { faKeyboard, faFaceSmileWink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./contact.css";

export default function Contact() {
  const SERVICE_ID = "service_tw2p0tc";
  const TEMPLATE_ID = "template_9i4872m";
  const USER_ID = "PDl2a1dgs4Ty7dCvp";
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Oops, something went wrong. Please try again later.",
          text: error.text,
        });
      }
    );
    e.target.reset();
  };
  return (
    <div className="contact-container">
      <FontAwesomeIcon icon={faKeyboard} className="contact-icons keyboard" />
      <FontAwesomeIcon icon={faFaceSmileWink} className="contact-icons smile" />
      <h1>Let's get in touch :)</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <MDBInput id="name" name="from_name" required />
        <label htmlFor="email">Email address</label>
        <MDBInput type="email" name="reply_to" id="email" required />
        <label htmlFor="message">Message</label>
        <textarea rows={5} id="message" name="message" required />
        <MDBBtn type="submit" className="custom-submit-btn">
          Submit
        </MDBBtn>
      </form>
    </div>
  );
}
