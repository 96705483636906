import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faArrowDown,
  faFish,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./mentions.css";

function Mentions() {
  return (
    <div className="mentions-container">
      <FontAwesomeIcon icon={faFish} className="mention-icons people" />
      <FontAwesomeIcon icon={faCalculator} className="mention-icons gift" />
      <div className="mention-boxes">
        <div className="mention-box">
          <a href="https://calorietracker.johngaynor.dev" target="_blank">
            <FontAwesomeIcon icon={faLink} />
          </a>
          <h2>Calorie Tracker</h2>
          <h5>
            Unhappy with popular calorie tracking apps, I went off and built my
            own.
          </h5>
          <div className="mention-skills">
            <span>React.js</span>
            <span>Firebase</span>
            <span>Bootstrap</span>
          </div>
        </div>
        <div className="mention-box">
          <a href="https://catfish-alpha.vercel.app" target="_blank">
            <FontAwesomeIcon icon={faLink} />
          </a>
          <h2>Chatfish</h2>
          <h5>
            Your favorite way to meet new people! 2023 IUPUI Hackathon 1st place
            UX/UI.
          </h5>
          <div className="mention-skills">
            <span>Next.js</span>
            <span>Tailwind.css</span>
            <span>Firebase</span>
          </div>
        </div>
        <div className="mention-box">
          <a href="https://www.sportstransformation.org/" target="_blank">
            <FontAwesomeIcon icon={faLink} />
          </a>
          <h2>CST Design</h2>
          <h5>
            Freelance project for a local organization promoting youth sports.
          </h5>
          <div className="mention-skills">
            <span>Wix</span>
            <span>Figma</span>
          </div>
        </div>
      </div>
      <h4>
        About Me
        <FontAwesomeIcon icon={faArrowDown} className="mx-2" />
      </h4>
    </div>
  );
}

export default Mentions;
