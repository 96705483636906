import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCube,
  faGears,
} from "@fortawesome/free-solid-svg-icons";

import pfp from "../../../assets/images/JOHN GAYNOR.jpg";
import styles from "./aboutMe.css";

function AboutMe() {
  return (
    <div className="about-container">
      <FontAwesomeIcon icon={faCube} className="icon-cube" />
      <FontAwesomeIcon icon={faGears} className="icon-gears" />
      <div className="about-box">
        <div className="about-profile">
          <div className="image-filter"></div>
          <img src={pfp} alt="profile picture" />
        </div>
        <div className="about-text">
          <h1 className="about-header">
            An Eye for <span className="header-orange-red">Optimization</span>
          </h1>
          <h5 className="mb-3">
            In my years as a competitive Rubik's Cube solver, I discovered an
            interest in using technology to optimize human tasks. This branched
            into a passion for developing applications that make life easier for
            its users. I believe this stuff has the power to revolutionize the
            world, and you won't catch me waiting around for someone else to do
            it!
          </h5>
          <div className="about-skills">
            <span>JavaScript</span>
            <span>React.js</span>
            <span>Tailwind.css</span>
            <span>Material UI</span>
            <span>Node.js</span>
            <span>SQL</span>
            <span>AWS</span>
            <span>Git</span>
            <span>Agile</span>
            <span>Jira</span>
          </div>
        </div>
        <h4>
          Like what you see?
          <FontAwesomeIcon icon={faArrowDown} className="mx-2" />
        </h4>
      </div>
    </div>
  );
}

export default AboutMe;
