import { useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBBtn,
  MDBModalBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModalHeader,
} from "mdb-react-ui-kit";

import ResumePDF from "../../assets/10-19-24 resume (redacted).pdf";

export default function Resume() {
  const [basicModal, setBasicModal] = useState(false);

  return (
    <>
      <h5 onClick={() => setBasicModal(!basicModal)}>RESUME</h5>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog size="fullscreen">
          <MDBModalContent style={{ backgroundColor: "#020122" }}>
            <MDBModalHeader style={{ border: "none" }}>
              <MDBBtn
                className="btn-close"
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: "white",
                }}
                color="danger"
                onClick={() => setBasicModal(!basicModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{ height: "100%" }}>
              <MDBContainer fluid style={{ height: "100%" }}>
                <MDBRow
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  <MDBCol col="12" style={{ height: "100%" }}>
                    <iframe
                      title="resume"
                      width="100%"
                      height="100%"
                      src={ResumePDF}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
